import { Button, Typography } from "@material-ui/core";

import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import { func } from "prop-types";
import React, { useContext } from "react";
import { ErrorBody, TestSuiteMembership } from "../../../generatedApi";
import { mapErrorBody, useErrorHandler } from "../../../services/helpers/ErrorHandler";
import TestMembershipsContext from "../../../stores//MembershipsStore";
import NotificationContext from "../../../stores/NotificationStore";
import { styles } from "../../Styles/layout";

export const DeleteTestContext = React.createContext({});

interface IProps {
  projectName: string;
}

export const DeleteTest = (props: IProps) => {
  const { openDeleteDialog, setOpenDeleteDialog, selected } = useContext(DeleteTestContext) as {
    openDeleteDialog: boolean;
    setOpenDeleteDialog: React.Dispatch<React.SetStateAction<boolean>>;
    selected: TestSuiteMembership | undefined;
  };
  const errorHandler = useErrorHandler();
  const notificationStore = useContext(NotificationContext);
  const tests = useContext(TestMembershipsContext);
  const [isConfirmed, setConfirmed] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  function toggleConfirm() {
    setConfirmed(!isConfirmed);
  }

  function handleIfCheck() {
    // tslint:disable-next-line: no-unused-expression
    isConfirmed && toggleConfirm();
  }

  function handleDelete(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoading(true);
    if (selected !== undefined && selected.test_name !== undefined) {
      const test_name = selected.test_name;
      try {
        tests.deleteTest(test_name);
      } catch (error) {
        const msg = errorHandler(error, {
          403: (data: ErrorBody) => (
            <Typography color="inherit">
              {mapErrorBody(data)}: <strong>{test.name!}</strong>
            </Typography>
          ),
        });
        notificationStore.enqueueAutohideSnackbar(msg, "error");
      }
    }
    setOpenDeleteDialog(false);
    handleIfCheck();
    setLoading(false);
  }

  return (
    <Dialog open={openDeleteDialog} fullWidth={true}>
      <DialogTitle>Are you sure you want to delete {selected ? selected.test_name : ""}</DialogTitle>
      <DialogContent>
        <FormControlLabel
          data-testid="delete_test_confirm"
          control={<Checkbox classes={{ root: "checkbox" }} checked={isConfirmed} onChange={toggleConfirm} />}
          label={
            "I understand that deleting this will permanently remove it from the system without the ability to restore it"
          }
        />
      </DialogContent>
      <form onSubmit={handleDelete}>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDeleteDialog(false);
              handleIfCheck();
            }}
            color="secondary"
            disabled={loading}
          >
            Cancel
          </Button>
          <Button data-testid="confirm_delete_test_button" type="submit" color="secondary" disabled={!isConfirmed}>
            Delete Test
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default withStyles(styles)(DeleteTest);
