import { FormControl, TextField, Typography, WithStyles } from "@material-ui/core";
import React, { ReactNode, useContext, useState } from "react";
import { ErrorBody, Suite } from "../../../generatedApi";
import { mapErrorBody, useErrorHandler } from "../../../services/helpers/ErrorHandler";
import SuitesContext from "../../../stores/SuiteStore";
import { styles } from "../../Styles/layout";
import SimpleDialog from "../../UI/SimpleDialog";

interface IUpdateSuiteProps extends WithStyles<typeof styles> {
  suite: Suite;
  onSuccess: (msg: ReactNode) => void;
  onFailure: (msg: ReactNode) => void;
  dialogActive: boolean;
  dialogToggle: () => void;
}

const UpdateSuite = (props: IUpdateSuiteProps) => {
  const errorHandler = useErrorHandler();
  const [suiteName, setSuiteName] = useState(props.suite.name);

  const suitesStore = useContext(SuitesContext);

  function closeDialog() {
    props.dialogToggle();
  }

  function handleInput(input: string) {
    setSuiteName(input);
  }

  async function handleSubmit() {
    try {
      await suitesStore.updateSuite(props.suite.uuid!, { name: suiteName });
      props.onSuccess("Successfully updated suite");
      closeDialog();
    } catch (error) {
      props.onFailure(
        errorHandler(error, {
          403: (data: ErrorBody) => {
            return (
              <Typography color="inherit">
                {mapErrorBody(data)}: <strong>{props.suite.name}</strong>
              </Typography>
            );
          },
        }),
      );
    }
  }

  return (
    <SimpleDialog
      open={props.dialogActive}
      title={"Rename Suite"}
      actionLabel="UPDATE"
      onAction={handleSubmit}
      onClose={closeDialog}
      extraProps={{ fullWidth: true }}
      actionButtonProps={{ "data-testid": "update_suite_button" }}
    >
      <FormControl className={props.classes.form}>
        <TextField
          data-testid="update_suite_name_input"
          autoFocus={true}
          value={suiteName}
          variant={"outlined"}
          onChange={evt => handleInput(evt.target.value as string)}
        />
      </FormControl>
    </SimpleDialog>
  );
};

export default UpdateSuite;
