import { FormControl, TextField, Typography, WithStyles } from "@material-ui/core";
import React, { ReactNode, useContext, useState } from "react";
import { ErrorBody, Project } from "../../../generatedApi";
import { mapErrorBody, useErrorHandler } from "../../../services/helpers/ErrorHandler";
import ProjectContext from "../../../stores/ProjectsStore";
import { styles } from "../../Styles/layout";
import SimpleDialog from "../../UI/SimpleDialog";

interface IUpdateProjectProps extends WithStyles<typeof styles> {
  project: Project;
  onSuccess: (msg: ReactNode) => void;
  onFailure: (msg: ReactNode) => void;
  dialogActive: boolean;
  dialogToggle: () => void;
}

const UpdateProject = (props: IUpdateProjectProps) => {
  const errorHandler = useErrorHandler();
  const [projectName, setProjectName] = useState(props.project.display);

  const projectsStore = useContext(ProjectContext);

  function closeDialog() {
    props.dialogToggle();
  }

  function handleInput(input: string) {
    setProjectName(input);
  }

  async function handleSubmit() {
    try {
      await projectsStore.updateProject(props.project.name!, { display: projectName });
      props.onSuccess("Successfully updated project");
      closeDialog();
    } catch (error) {
      props.onFailure(
        errorHandler(error, {
          403: (data: ErrorBody) => {
            return (
              <Typography color="inherit">
                {mapErrorBody(data)}: <strong>{props.project.display}</strong>
              </Typography>
            );
          },
        }),
      );
    }
  }

  return (
    <SimpleDialog
      open={props.dialogActive}
      title={"Rename Project"}
      actionLabel="UPDATE"
      onAction={handleSubmit}
      onClose={closeDialog}
      extraProps={{ fullWidth: true }}
      actionButtonProps={{ "data-testid": "update_project_button" }}
    >
      <FormControl className={props.classes.form}>
        <TextField
          data-testid="update_project_name_input"
          autoFocus={true}
          value={projectName}
          variant={"outlined"}
          onChange={evt => handleInput(evt.target.value as string)}
        />
      </FormControl>
    </SimpleDialog>
  );
};

export default UpdateProject;
