import { ApiConf } from "../../config";
import { AccountApi, BenchApi, OrganizationApi, ProjectApi, SessionApi, SuiteApi, TestApi } from "../../generatedApi";

const suiteApi = new SuiteApi(ApiConf);
const benchApi = new BenchApi(ApiConf);
const projectApi = new ProjectApi(ApiConf);
const sessionApi = new SessionApi(ApiConf);
const organizationApi = new OrganizationApi(ApiConf);
const accountApi = new AccountApi(ApiConf);
const testApi = new TestApi(ApiConf);

export { suiteApi, benchApi, projectApi, sessionApi, organizationApi, accountApi, testApi };
