import { action, computed, observable } from "mobx";
import { createContext } from "react";
import { Project, UpdateProject } from "../generatedApi";
import { projectApi } from "../services/api";

export class ProjectsStore {
  @observable private projectsRegistry = observable.map<string, Project>();
  @observable private organizationId: string = "";

  @computed get projects() {
    return Array.from(this.projectsRegistry.values());
  }
  set projects(projects: Project[]) {
    this.projectsRegistry.clear();
    projects.forEach(project => {
      if (project.name) {
        this.projectsRegistry.set(project.name, project);
      }
    });
  }

  @action public setOrganization(organizationId: string) {
    if (this.organizationId !== organizationId) {
      this.projectsRegistry.clear();
      this.organizationId = organizationId;
    }
  }

  @action
  public async loadProjects() {
    try {
      const response = await projectApi.listProjects(this.organizationId);
      this.projects = response.data;
    } catch (error) {
      throw error;
    }
  }

  @action public async loadProject(projectName: string) {
    try {
      const response = await projectApi.getProjectByName(projectName);
      if (response.data.name) {
        this.projectsRegistry.set(response.data.name, response.data);
      }
    } catch (error) {
      throw error;
    }
  }

  @action public async createProject(project: Project) {
    try {
      const response = await projectApi.addProject(this.organizationId, project);
      if (response.data.name) {
        this.projectsRegistry.set(response.data.name, response.data);
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  @action public async deleteProject(projectName: string) {
    try {
      await projectApi.deleteProject(projectName);
      this.projectsRegistry.delete(projectName);
    } catch (error) {
      throw error;
    }
  }

  public getProject(projectName: string) {
    const project = this.projectsRegistry.get(projectName);
    if (project) {
      return project;
    }
    return { display: "" };
  }

  @action public async updateProject(projectName: string, updateProj: UpdateProject) {
    try {
      await projectApi.updateProject(projectName, updateProj);
      await this.loadProject(projectName);
    } catch (error) {
      throw error;
    }
  }
}

const projectsStore = new ProjectsStore();
export default createContext(projectsStore);
