import { TableBody } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { match } from "react-router";
/* stores */
import { TestSuiteMembership } from "../../../generatedApi";
import { useErrorHandler } from "../../../services/helpers/ErrorHandler";
import TestMembershipsContext from "../../../stores/MembershipsStore";
import SuitesContext from "../../../stores/SuiteStore";
import Conditional from "../../Conditional";
import { styles } from "../../Styles/layout";
import EmptyState from "../../UI/EmptyState";
import AddTestToSuite, { AddTestToSuiteContext } from "./AddTestToSuite";
import DeleteTest, { DeleteTestContext } from "./DeleteTest";
import ManageMembershipListItem from "./ManageMembershipListItem";

export const MANAGE_MEMBERSHIP_KEY = "membership";

interface IProps extends WithStyles<typeof styles> {
  match: match<{ projectName: string; suiteId: string }>;
}

export const ManageMembership = observer((props: IProps) => {
  const [loaded, setLoaded] = useState(false);
  const [selected, select] = useState<TestSuiteMembership | undefined>();

  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const errorHandler = useErrorHandler();

  const membershipsStore = useContext(TestMembershipsContext);
  const suitesStore = useContext(SuitesContext);
  const { match, classes } = props;

  useEffect(() => {
    const fetchData = async () => {
      try {
        membershipsStore.setProject(match.params.projectName);
        await membershipsStore.loadMemberships();
        suitesStore.setProject(match.params.projectName);
        await suitesStore.loadSuites();
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoaded(true);
      }
    };
    fetchData();
  }, []);

  const EmptyTests = observer(() => {
    const description = <React.Fragment>There are no test results in the project.</React.Fragment>;
    return (
      <EmptyState Icon={InsertChartIcon} label="All Tests" description={description}>
        {null}
      </EmptyState>
    );
  });

  return (
    <React.Fragment>
      <Conditional if={!loaded}>
        <CircularProgress />
      </Conditional>
      <Conditional if={loaded && membershipsStore.memberships.length === 0}>
        <EmptyTests />
      </Conditional>
      <Conditional if={loaded && membershipsStore.memberships.length > 0}>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select value={0} input={<OutlinedInput labelWidth={0} name="get_latest" id="outlined-age-simple" />}>
            <MenuItem value={0}>Newest to Oldest</MenuItem>
          </Select>
        </FormControl>
        <Typography variant="h5">All Tests</Typography>
        <Typography variant="subtitle1" paragraph={true}>
          &nbsp;
        </Typography>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.borderRight}>Tests</TableCell>
              <TableCell align="left">Suites</TableCell>
              <TableCell align="right">&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-testid="test_membership_table">
            <AddTestToSuiteContext.Provider value={{ openAddDialog, setOpenAddDialog, selected, select }}>
              <DeleteTestContext.Provider value={{ openDeleteDialog, setOpenDeleteDialog, selected }}>
                <AddTestToSuite projectName={match.params.projectName} />
                <DeleteTest projectName={match.params.projectName} />
                {membershipsStore.memberships.map((membership: TestSuiteMembership) => {
                  return (
                    <TableRow
                      key={membership.test_name}
                      hover={true}
                      onClick={() => {
                        select(membershipsStore.getMembership(membership.test_name));
                      }}
                    >
                      <ManageMembershipListItem membership={membership} />
                    </TableRow>
                  );
                })}
              </DeleteTestContext.Provider>
            </AddTestToSuiteContext.Provider>
          </TableBody>
        </Table>
      </Conditional>
    </React.Fragment>
  );
});

export default withStyles(styles)(ManageMembership);
